<template>
    <o-data-lookup :data-object="dsLookupDataObject" :whereClause="getWhereClause" reloadOnWhereClauseChange>
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">                
            </span>
        </template>
        <template #toolbarActions>
            <div class="form-check">
                <input class="form-check-input" id="dicLookup_RestrictToContext" type="checkbox" v-model="restrictedToContext" @click="toggleContext">
                <label class="form-check-label" for="dicLookup_RestrictToContext">{{ $t("Restrict to Context") }}</label>
            </div>
        </template>

        <o-column field="ID" width="80"></o-column>
        <o-column field="Name" width="150"></o-column>
        <o-column field="Title" width="300"></o-column>
    </o-data-lookup>
</template>

<script setup>
    import { computed, ref } from 'vue';
    import { getOrCreateDataObject } from 'o365.vue.ts'
    import context from "o365.modules.Context.ts";

    const restrictedToContext = ref(true);
    const props = defineProps({
        is: String,
        IdPath: String,      
        textInputValue: String,
        textInput: Boolean
    });

    const dsLookupDataObject = getOrCreateDataObject({
        id: 'dsLookupDataObject' + crypto.randomUUID(),
        viewName: 'aviw_Assets_DisciplinesLookup',
        maxRecords: 25,
        whereClause: "",
        loadRecents: false,
        distinctRows: true,
        fields:
             [{name: "ID", type: "number" },
             {name: "Name", type: "string", sortOrder: 1, sortDirection: "asc" },             
             {name: "Title", type: "string" },      
             {name: "NameAndTitle", type: "string" }]
    });

    const getWhereClause = computed( () => {
        var vFilter = [];

        vFilter.push("Closed IS NULL");
        
        if (restrictedToContext.value) {    
            if (props.IdPath) {
                vFilter.push("'" + props.IdPath + "' LIKE IdPath + '%'");
            }
            else {
                vFilter.push("'" + context.idPath + "' LIKE IdPath + '%'");
            }
        }   

        return vFilter.join(" AND ");
    });
        

    function toggleContext(e,o) {
        restrictedToContext.value = !restrictedToContext.value;
    }
    
</script>